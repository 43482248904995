import { Link } from 'gatsby';
import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import Section from '../generic/Section';
import { mergeClassNames } from 'utils';
import {
  universalMargin,
  maxLayoutWidth,
  branding,
  anchor,
  navType,
  gridPaddings,
  mediumBody,
  mediumBodyEmphasized,
  mediaQueries,
} from 'styles';
import { white } from 'styles/baseColors';
import { useCallbackOnDirectionalMovement } from './useCallbackOnDirectionalMovement';
import { getBuildTarget, isBuildTargetWebex } from 'white-label';

interface BreadcrumbsProps {
  children?: React.ReactNode;
  neverHide?: boolean;
  className?: string;
}

export default function Breadcrumbs({ children, neverHide = false, className = '' }: BreadcrumbsProps) {
  if (!children) return null;
  return (
    <BreadcrumbsContainer>
      <ul>{children}</ul>
    </BreadcrumbsContainer>
  );
}

export function BreadcrumbsContainer(props: BreadcrumbsProps & { innerPadding?: boolean }) {
  const [isClosed, set] = useState(false);
  useCallbackOnDirectionalMovement(!isBuildTargetWebex() ? 100 : 1, set);

  const navSection = (
    <StyledNavSection
      className={mergeClassNames(isClosed && !props.neverHide ? 'hidden' : '', getBuildTarget(), props.className)}
      innerPadding={props.innerPadding || false}
    >
      {props.children}
    </StyledNavSection>
  );

  return (
    <>
      <NavSectionFixedContainer>{navSection}</NavSectionFixedContainer>
      <NavSectionFlowingContainer>{navSection}</NavSectionFlowingContainer>
    </>
  );
}

interface NavSectionProps {
  className: string;
  children: ReactNode;
  innerPadding: boolean;
}

function NavSection({ className, children, innerPadding }: NavSectionProps) {
  return (
    <Section as="nav" size="max" innerClassName="inner" className={className} innerPadding={innerPadding}>
      {children}
    </Section>
  );
}

const webexHeaderHeight = 98 + 34;

const StyledNavSection = styled(NavSection)`
  background: ${branding.cardGrid.item.default.background};
  padding: ${universalMargin * 3}px 0;
  transform: translate(0, 0);
  transition: transform 0.4s;
  max-width: ${maxLayoutWidth}px;
  margin: 0 auto;
  z-index: 200;
  li {
    display: inline;
  }
  &.hidden {
    transform: translate(0, -100%);
  }
  display: none;
  ${mediaQueries.small(css`
    display: flex;
  `)}

  &.webex {
    background-color: black;
    color: ${white};
    transition: transform 0.3s linear;
    &.hidden {
      transform: translate(0, -${webexHeaderHeight - 10}px) translate(0, -100%);
    }
    &::before {
      /* here 👇 we add some background to the top of the nav bar to fix flicker between hardware.webex header and this nav */
      content: '';
      position: absolute;
      width: 100%;
      background: inherit;
      height: 50px;
      top: -50px;
    }
    // Breakpoint from webex navigation
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .inner {
    ${gridPaddings}
  }

  .breadcrumb {
    display: block;
    padding-bottom: ${universalMargin}px;
    ${mediaQueries.small(css`
      display: unset;
      white-space: nowrap;
      padding-bottom: unset;
    `)}
    &:not(.location .breadcrumb):not(.location.breadcrumb) {
      a {
        ${navType};
      }
      &:not(:last-child) {
        padding-right: 30px;
      }
    }
  }

  .breadcrumb.location {
    ${mediumBody}
    display: inline-block;
    padding-right: ${universalMargin * 8}px;
    li:not(:last-child)::after {
      content: ' / ';
    }
    li:last-child a {
      ${mediumBodyEmphasized}/* @include medium-body-emphasized; */
    }
  }

  ${mediaQueries.large(css`
    .breadcrumb {
      height: 100%;
    }
    &.default .inner {
      flex-wrap: nowrap;
    }
  `)}
`;

const NavSectionFlowingContainer = styled.div`
  @media screen {
    visibility: hidden;
  }
`;

const NavSectionFixedContainer = styled.div`
  z-index: 200;
  position: fixed;
  width: 100%;
  max-width: ${maxLayoutWidth}px;
  @media print {
    visibility: hidden;
  }
`;

const breadcrumbStyles = css`
  ${anchor}
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  ${breadcrumbStyles}
  .active {
    cursor: default;
  }
`;

interface LocationBreadcrumbsProps {
  children?: React.ReactNode;
}

export function LocationBreadcrumbs({ children }: LocationBreadcrumbsProps) {
  return (
    <li className={mergeClassNames('breadcrumb', 'location')}>
      <ul>{children}</ul>
    </li>
  );
}

interface BreadcrumbLinkProps {
  to?: string;
  children?: React.ReactNode;
}

export function BreadcrumbLink({ to, children }: BreadcrumbLinkProps) {
  return (
    <li className={mergeClassNames('breadcrumb')}>
      {to ? (
        <StyledLink to={to} activeClassName="active" activeStyle={{ fontWeight: '700' }}>
          {children}
        </StyledLink>
      ) : (
        <span>{children}</span>
      )}
    </li>
  );
}

interface BreadcrumbGoToAnchorProps {
  id: string;
  children?: React.ReactNode;
}

export function BreadcrumbGoToAnchor({ id, children }: BreadcrumbGoToAnchorProps) {
  return <BreadcrumbLink to={`#${id}`}>{children}</BreadcrumbLink>;
}
