import NavGrid, { GridItem, NavGridItemStill } from '../../generic/NavGrid';
import { Link, graphql } from 'gatsby';
import Section from '../../generic/Section';
import { ContentfulPage, ContentfulWorkspaceSubCategory } from 'types';
import NavGridItemHero from '../../generic/NavGrid/NavGridItemHero';
import React from 'react';
import styles from './index.module.scss';
import { useLocation } from '@reach/router';

/**
 * @param {object} props
 * @param {string} props.heading
 * @param {string} props.description
 * @param {Array<ContentfulWorkspaceSubCategory|ContentfulPage>} props.workspaceSubcategories
 */
export default function WorkspaceSelection({ heading, description = '', workspaceSubcategories }) {
  const location = useLocation();
  const linkpath = location?.pathname.replace(/\/$/, '');

  return (
    <Section size="max">
      <NavGrid mapRowHeightToColumnWidth>
        <NavGridItemHero heading={heading} description={description} />
        {workspaceSubcategories?.map(subcategory => {
          // setting different sizes for different pixel ratios here since safari tend to download the largest
          // possible image from the srcSet on dynamic sizes (or rather doesn't cancel the download initiated before the js is loaded)
          switch (subcategory.__typename) {
            case 'ContentfulWorkspaceSubCategory':
              return <WorkspaceCard key={subcategory.id} subcategory={subcategory} linkpath={linkpath} />;
            case 'ContentfulPage':
              return null;
            default:
              throw new Error(`unknown <Workspace> grid type "${subcategory}"`);
          }
        })}
      </NavGrid>
    </Section>
  );
}

/**
 * @param {object} props
 * @param {ContentfulWorkspaceSubCategory} props.subcategory
 * @param {string} props.linkpath
 */
function WorkspaceCard({ subcategory, linkpath }) {
  if (!subcategory || !subcategory.workspaces) {
    return null;
  }

  let [defaultWorkspace] = subcategory.workspaces;
  let [gridImage = subcategory.image] = subcategory.images || [];
  //              👆 fallback to old image until all sub categories have transitioned to [images]-type
  let { x, y } = {
    x: !isNaN(gridImage?.focalPoint?.x) ? gridImage.focalPoint.x : 50,
    y: !isNaN(gridImage?.focalPoint?.y) ? gridImage?.focalPoint?.y : 50,
  };
  let imagePosition = `${x}% ${y}%`;

  return (
    <GridItem key={subcategory.id} gridSize={subcategory.gridSize}>
      <Link className={styles.link} to={linkpath + '/' + subcategory.slug + '/' + defaultWorkspace.slug}>
        <NavGridItemStill
          key={subcategory.id}
          objectFit="cover"
          objectPosition={imagePosition}
          heading={subcategory.shortHeading || subcategory.heading}
          description={subcategory.description?.description}
          image={gridImage.image}
          numberOfSeats={subcategory.numberOfSeats}
          physicalSizeM2={subcategory.physicalSizeM2}
          gridSize={subcategory.gridSize}
          gridImagePad
        />
      </Link>
    </GridItem>
  );
}

export const fragment = graphql`
  fragment WorkspaceSelectionSubcategory on ContentfulWorkspaceSubCategory {
    __typename
    id
    heading
    shortHeading
    slug
    # leave this until all sub categories' images is transitioned into [images]
    image {
      ...GridImage
    }
    images {
      ...PwpImageBase
      image {
        ...ImageBase
        gatsbyImageData(layout: CONSTRAINED, width: 640, placeholder: NONE)
      }
    }
    numberOfSeats
    physicalSizeM2
    description {
      description
    }
    workspaces {
      slug
    }
    workspaceCategory: workspace_category {
      slug
    }
    gridSize
  }
`;
