import React from 'react';
import { graphql } from 'gatsby';
import Head from '../components/Head';
import WorkspaceSelection from '../components/WorkspaceGrid/WorkspaceSelection';
import WorkspaceSubCatBreadcrumb from '../components/WorkspaceGrid/WorkspaceSubCatBreadcrumb';
import styled from 'styled-components';
import { branding, mediaQueries, universalMargin } from 'styles';

export default function WorkspaceSubCategories({ data: { workspaceCategory }, location, path }) {
  let heading = workspaceCategory.heading;
  let description = workspaceCategory.description?.description;
  let [categoryImage] = workspaceCategory.images || [];
  let image = categoryImage?.image?.file?.url;

  return (
    <>
      <Head location={location} title={heading} description={description} image={image} />
      <WorkspaceSubCatBreadcrumb
        subCategories={workspaceCategory.workspaceSubCategories}
        workspaceSubCatHeading={workspaceCategory.heading}
        workspaceSubCatLink={path}
      />
      <SelectionContainer>
        <WorkspaceSelection
          heading={workspaceCategory.heading}
          description={workspaceCategory.body?.body}
          workspaceSubcategories={workspaceCategory.workspaceSubCategories}
        />
      </SelectionContainer>
    </>
  );
}

const SelectionContainer = styled.div`
  background-color: ${branding.cardGrid.background};
  padding-top: ${universalMargin * 2}px;
  margin-bottom: ${universalMargin * 8}px;
  ${mediaQueries.large(`padding-top: ${universalMargin * 8}px;`)}
  ${mediaQueries.large(`margin-bottom: ${universalMargin * 16}px;`)}
`;

export const query = graphql`
  query ($id: String!) {
    workspaceCategory: contentfulWorkspaceCategory(id: { eq: $id }) {
      id
      heading
      slug
      numberOfSeats
      body {
        body
      }
      description {
        description
      }
      images {
        image {
          file {
            url # use gatsby-plugin-image helper getSrc() (?) here
          }
        }
      }
      gridSize
      workspaceSubCategories {
        ...WorkspaceSelectionSubcategory
      }
    }
  }
`;
