import React from 'react';
import getRoute from '../../../routes/getRoute';
import Breadcrumbs, { BreadcrumbLink, LocationBreadcrumbs } from '../../SecondaryNavigation/Breadcrumbs';
import styles from './index.module.scss';

export default function WorkspaceSubCatBreadcrumb({ subCategories, workspaceSubCatHeading, workspaceSubCatLink }) {
  if (!subCategories || subCategories.length === 0) {
    return null;
  }
  return (
    <Breadcrumbs className={styles.workspaceBreadcrumb}>
      <LocationBreadcrumbs>
        <BreadcrumbLink to="/workspaces">Workspaces</BreadcrumbLink>
        <BreadcrumbLink to={workspaceSubCatLink}>{workspaceSubCatHeading}</BreadcrumbLink>
      </LocationBreadcrumbs>
      {subCategories.map(subCategory => {
        return (
          <BreadcrumbLink key={subCategory.slug} to={getRoute(subCategory)}>
            {subCategory.shortHeading || subCategory.heading || subCategory.title}
          </BreadcrumbLink>
        );
      })}
    </Breadcrumbs>
  );
}
